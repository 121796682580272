import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
// import "./service.css"
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import NewHomService from "../../Service/NewHomService";
import moment from "moment";
import ReactDOM from 'react-dom';
import Countdown from 'react-countdown';
import Loader from "../../Component/Loader";
import VendorListByCategory from "./VendorListByCategory";
import { isValidUrl } from "../../Utils/Helpers";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";
import { Label } from "reactstrap";


var vendorIdGlobal = null;

const AddBooking = () => {
    const { bstatus } = useParams()
    const [name, setName] = useState("");
    // const [title, settitle] = useState('')
    const [exp_date, setexp_date] = useState("");
    const [couponPercent, setcouponPercent] = useState("");
    const [allData, setAllData] = useState([]);
    const [description, setdescription] = useState("");

    const [allVendor, setAllVendor] = useState([]);
    const [aVendor, setAVendor] = useState("")
    const [aStatus, setAStatus] = useState("")

    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState("")
    const [loader, setLoader] = useState(false)

    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const [hide, setHide] = useState(true);
    const [id, setId] = useState("");

    console.log(filteredData, "filteredData")

    useEffect(() => {
        fetchAllBooking();
        fetchAllVendor()
        // console.log("TIME",startTimer(60 * 5));
    }, []);

    // useEffect(()=>{
    //     fetchCSVUsers();
    //   },[filteredData])

    const resetState = () => {
        setName("");
        setexp_date("");
        setcouponPercent("");
        setdescription("");

        // setAllData('')
        // setquestion("")
    };
    const onEdit = (item) => {
        console.log("item", item);
        setName(item?.name);
        setId(item?._id);
        setHide(false);
    };

    const onDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            // text: "You won't  to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                HomeService.DeleteState(id)
                    .then((res) => {
                        console.log("DELETEBLOGCAT", res);
                        if (res && res.status) {
                            toast.success("Deleted Successfully");
                            console.log("RESPONSEDELETE", res);
                            // fetchAllCategory();
                        } else {
                            toast.error("Failed to Delete the Item ");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    const updateStatus = (val, id) => {
        // console.log("Status", e.target.value);
        console.log("ID", id);
        let data = {
            bookingStatus: val,
        };

        NewHomService.UpdateBookingStatus(id, data)
            .then((res) => {
                console.log("updated res", res);
                if (res && res.status) {
                    toast.success("Updated Successfully");
                    //   setHide(true);
                    console.log("RESPONSEDELETE", res);
                    fetchAllBooking();
                    resetState();
                } else {
                    toast.error("Blog Category Name Already Exist");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    /////////////////Countdown////////////////////


    function timeSince(date) {

        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " years";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    }




    /////////////////////////Countdown/////////////

    const fetchAllBooking = () => {
        setLoader(true)
        HomeService.ManageBookingCheckout()
            .then((res) => {
                console.log("ALLDATAER", res.data);
                if (res && res?.status) {
                    setLoader(false)

                    let arr = res?.data?.sort().reverse().map((item, index) => {
                        // let serviceTotal = item?.serviceData.reduce((total, service) => total + service?.discountPrice);
                        return {

                            sl: index + 1,

                            ID: item?._id,
                            BookingID: item?.order_id,

                            quantity: item?.quantity,
                            subtotal: item?.quantity !== undefined ? item?.subtotal * item?.quantity : item?.subtotal,

                            // subt: item?.serviceData.reduce((total, service) => total + service?.discountPrice),

                            name: item?.name,
                            // image: IMAMGE_URL + item?.image,
                            // image: item?.image,

                            image: item?.userDetail?.img,
                            price: item?.price,
                            // total: item?.total,
                            itemDiscount: item?.itemDiscount,

                            couponCode: item?.couponCode,
                            couponPrice: item?.couponPrice,
                            membershipDiscount: item?.membershipDiscount,
                            // totalAmount: item?.totalAmount,
                            address: item?.address,
                            zipcode: item?.zipcode,
                            landmark: item?.landmark ? item?.landmark : "N/A",
                            city: item?.city ? item?.city : item?.cityDetail?.name,
                            // categorydata: item?.serviceData[0]?.categoryID,

                            mobileNumber: item?.mobileNumber,
                            userName: item?.userDetail?.name,
                            userMobileNumber: item?.userDetail?.mobileNo,

                            addressType: item?.addressType,
                            paymentMethod: item?.paymentMethod,
                            paymenttype: item?.paymenttype,
                            bookingStatus: item?.bookingStatus,
                            booking_date: item?.booking_date,
                            booking_time: item?.booking_time,
                            discount_percent: item?.discount_percent,
                            updatedOn: item?.updatedAt,
                            coupon: item?.Coupon[0]?.couponCode,
                            couponDiscount: item?.Coupon[0]?.couponCode !== undefined ? item?.Coupon[0]?.discountAmount : 0,
                            Service: item?.serviceData,
                            acceptanceArray: item?.acceptanceArray,
                            membershipPlan: item?.userSubscriptionData[0]?.subscriptionData?.name,
                            membershipDiscountPercentage: item?.userSubscriptionData[0]?.subscriptionData?.offerPercentage !== undefined ? item?.userSubscriptionData[0]?.subscriptionData?.offerPercentage : 0,

                            Orders: item?.orderDetails,


                            membershipDiscount: item?.userSubscriptionData[0]?.subscriptionData?.offerPercentage !== undefined ? parseFloat(item?.subtotal) * (item?.userSubscriptionData[0]?.subscriptionData?.offerPercentage / 100) : 0,
                            bookingTotal: item?.subtotal -
                                (item?.Coupon[0]?.discountAmount !== undefined ? (item?.subtotal * (item?.Coupon[0]?.discountAmount / 100)) : 0) -
                                (item?.userSubscriptionData[0]?.subscriptionData?.offerPercentage !== undefined ? ((item?.subtotal) * (item?.userSubscriptionData[0]?.subscriptionData?.offerPercentage / 100)) : 0),

                            action: (
                                <div style={{ display: "flex", flexDirection: "coloum" }}>
                                    {/* <svg
                    onClick={() => onEdit(item)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg> */}
                                    <svg
                                        onClick={() => onDelete(item?._id)}
                                        style={{
                                            color: "red",
                                            height: "20px",
                                            cursor: "pointer",
                                            width: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-trash3"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>
                                </div>
                            ),
                        };
                    });
                    // console.log("LINE269", bstatus);

                    bstatus && (
                        arr = arr.filter((item) => {
                            if (item?.bookingStatus?.toLowerCase()?.includes(bstatus)) {
                                return item;
                            }
                        })

                    )
                    bstatus && (
                        setSearchOrderStatus(bstatus)
                    )




                    setFilteredData(arr)
                    setAllData(arr);
                    fillCSVData(arr);
                }
                // console.log("RESPONSE", res);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    // const AddService = () => {
    //   let data = {
    //     couponCode: name,
    //     quantity: quantity,
    //     subtotal: subtotal,
    //     firstname: firstname,
    //     lastname: lastname,
    //     price: price,
    //     total: total,
    //     itemDiscount: itemDiscount,

    //     couponCode: couponCode,
    //     couponPrice: couponPrice,
    //     membershipDiscount: membershipDiscount,
    //     totalAmount: totalAmount,
    //     address: address,
    //     zipcode: zipcode,
    //     landmark: landmark,
    //     city: city,

    //     mobileNumber: mobileNumber,
    //     addressType: addressType,
    //     paymentMethod: paymentMethod,
    //     paymenttype: paymenttype,
    //     bookingStatus: bookingStatus,
    //     booking_date: booking_date,
    //     discount_percent: discount_percent,
    //     updatedOn: updatedOn,
    //   };

    //   if (name && couponPercent && exp_date && description) {
    //     // alert(name + country_id)
    //     HomeService.AddCupon(data)
    //       .then((res) => {
    //         console.log("Response Add State", res);
    //         if (res && res.status) {
    //           toast.success(res.message);
    //           fetchAllBooking();
    //           resetState();
    //         } else {
    //           toast.error(res.message);
    //         }
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   } else {
    //     if (!name) {
    //       toast.error("CouponCode Field Is Required");
    //     }

    //     if (!couponPercent) {
    //       toast.error("CouponPercent Field Is Required");
    //     }
    //     if (!description) {
    //       toast.error("Description Field Is Required");
    //     }
    //   }
    // };

    //     {
    //         name: (
    //             <div
    //                 style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //             >
    //                 SL
    //             </div>
    //         ),
    //         selector: (row) => row.sl,
    //     },
    //     {
    //         name: (
    //             <div
    //                 style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //             >
    //                 Coupon Code
    //             </div>
    //         ),
    //         selector: (row) => row.Name,
    //     },

    //     {
    //         name: (
    //             <div
    //                 style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //             >
    //                 Expirydate
    //             </div>
    //         ),
    //         selector: (row) => row.expirydate,
    //     },
    //     {
    //         name: (
    //             <div
    //                 style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //             >
    //                 Details
    //             </div>
    //         ),
    //         selector: (row) => row.description,
    //     },

    //     {
    //         name: (
    //             <div
    //                 style={{
    //                     fontSize: "14px",
    //                     color: "#495057",
    //                     marginLeft: "15px",
    //                     fontWeight: "bolder",
    //                 }}
    //             >
    //                 Action
    //             </div>
    //         ),
    //         selector: (row) => row.action,
    //     },
    // ];

    const fetchAllVendor = () => {
        NewHomService.FetchVendors()
            .then((res) => {
                // console.log("AllState", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data;
                    setAllVendor(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const assignVendor = (id, vendorID) => {

        // console.log("ASSIGNVENDOR", id, vendorID);
        let data = {
            orderId: id,
            vendorId: vendorID
        };
        // console.log("DATAZ",data);
        // return
        // Swal.fire(`Vendor assigned successfully`);

        vendorID ? (
            NewHomService.AssignVendorForDomesticService(data)
                .then((res) => {
                    console.log("RES", res);
                    if (res && res.status) {
                        toast.success("Updated Successfully");
                        console.log("RESPONSEDELETE", res);
                        fetchAllBooking();
                        setAVendor("")
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        ) : (
            toast.error("Choose vendor...")
        )

    }


    const filterdate = (date) => {
        const dateString = date;
        const extractedDate = dateString.split("T")[0];
        const formattedDate = new Date(extractedDate).toISOString().split("T")[0];
        // console.log("formattedDate", formattedDate);
        return formattedDate;
    };

    const onFilter = () => {
        let searchData = allData;
        //Order Status
        console.log("GSGS482", searchData);
        if (searchOrderStatus) {
            searchData = searchData.filter((item) => {
                if (item?.bookingStatus?.toLowerCase()?.includes(searchOrderStatus)) {
                    return item;
                }
            });
        }
        //Payment Status
        console.log("GSGS482", searchOrderStatus, searchData);
        if (searchPaymentStatus) {
            searchData = searchData.filter((item) => {
                if (item?.paymenttype?.toLowerCase()?.includes(searchPaymentStatus)) {
                    return item;
                }
            });
        }
        //Order Date
        if (fromDate) {
            searchData = searchData.filter((item) => {
                let itemDate = new Date(item?.booking_date)
                let frmDt = new Date(fromDate)
                let toDt = new Date(toDate)
                if (
                    itemDate.getDate() >= frmDt.getDate() &&
                    itemDate.getDate() <= toDt.getDate()
                ) {

                    return item;
                }
            });
        }

        setFilteredData(searchData);
        fillCSVData(searchData)

        // if (searchText !== "") {
        //     console.log("allData", allData);
        //     console.log("searchText", searchText);
        //     let searchData = allData.filter((item) => {
        //         if (

        //             moment(item?.booking_date).format("YYYY-MM-DD")
        //                 .includes(moment(searchText).format("YYYY-MM-DD"))
        //         ) {
        //             return item;
        //         }
        //     });
        //     setFilteredData(searchData)
        // } else {
        //     setFilteredData(allData)
        // }
    }

    const viewAllData = () => {
        // setSearchText("")
        setSearchOrderStatus("")
        setFromDate(new Date())
        setToDate(new Date())
        setFilteredData(allData)
        fillCSVData(allData)
    }


    const columns = [
        {
            name: <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>SL</div>,
            selector: (row) => row.sl,
            width: "75px",
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Booking Date & Time
                </div>
            ),
            selector: (row) => {
                return (<>{moment(row.updatedOn).format("DD-MM-YYYY hh:mm A")}</>)
            },
            wrap: true,
            width: "120px"
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Slot Date
                </div>
            ),
            selector: (row) => {
                return (<>{moment(row.booking_date).format("DD-MM-YYYY")}</>)
            },
            wrap: true,
            width: "120px"
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Slot Time
                </div>
            ),
            selector: (row) => {
                return (<>{moment(row.booking_time, "HH:mm").format("HH:mm A")}</>)
            },
            wrap: true,
            width: "120px"
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Booking ID
                </div>
            ),
            selector: (row) => {
                return <>{row.BookingID !== undefined && row.BookingID !== "" ? row.BookingID : "N/A"}</>
            },
            width: "140px",
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Customer Name
                </div>
            ),
            selector: (row) => {
                return <>{row.name !== undefined && row.name !== "" ? row.name : "N/A"}</>
            },
            width: "150px",
            wrap: true,
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    User Name
                </div>
            ),
            selector: (row) => {
                return <>{row.userName !== undefined && row.userName !== "" ? row.userName : "N/A"}</>
            },
            width: "150px",
            wrap: true,
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    User Mobile No.
                </div>
            ),
            selector: (row) => {
                return <>{row.userMobileNumber !== undefined && row.userMobileNumber !== "" ? row.userMobileNumber : "N/A"}</>
            },
            width: "150px",
            wrap: true,
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Image
                </div>
            ),
            selector: (row) => {
                let imgUrl = row.image
                !isValidUrl(imgUrl) && (imgUrl = IMAMGE_URL + imgUrl)
                return <>{row.image !== undefined && row.image !== "" ? <><img style={{ width: "100%", borderRadius: "1em" }} src={imgUrl} alt={""} /></> : "N/A"}</>
            },
        },

        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Address
                </div>
            ),
            selector: (row) => row.address,
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Landmark
                </div>
            ),
            selector: (row) => row.landmark,
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    City
                </div>
            ),
            selector: (row) => row.city,
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Mobile
                </div>
            ),
            selector: (row) => row.mobileNumber,
            width: "120px"
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Address Type
                </div>
            ),
            selector: (row) => row.addressType,
        },
        // {
        //     name: (
        //         <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
        //             Service(s)
        //         </div>
        //     ),
        //     selector: (row) => {
        //         return <><ul>{
        //             row.Service?.map((item, key) => {
        //                 return (<>
        //                     <li>{item?.title}</li>
        //                 </>)
        //             })
        //         }</ul></>
        //     },
        //     wrap: true,
        //     width: "200px"
        // },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Quantity
                </div>
            ),
            selector: (row) => {
                return row?.Orders.length > 0 ? (
                    <>

                        {
                            row?.Orders.map((item, key) => {
                                return (<>
                                    <p>{item?.serviceDetail?.title}&nbsp;(Qty: {item?.quantity})</p>
                                </>)
                            })
                        }

                    </>
                ) : (
                    <></>
                )
            },
            width: "250px",
            wrap: true,
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Service Total
                </div>
            ),
            // selector: (row) => row.subtotal.toFixed(2),
            selector: (row) => row.subtotal,
        },
        // {
        //     name: (
        //         <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
        //             Price
        //         </div>
        //     ),
        //     selector: (row) => row.price,
        // },
        // {
        //     name: (
        //         <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
        //             Total
        //         </div>
        //     ),
        //     selector: (row) => row.total,
        // },
        // {
        //     name: (
        //         <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
        //             Item Discount
        //         </div>
        //     ),
        //     selector: (row) => row.itemDiscount,
        // },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Coupon Code
                </div>
            ),
            selector: (row) => {
                return row?.coupon !== undefined ? <>{row.coupon}</> : "n/a"
            },
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Coupon Discount
                </div>
            ),
            selector: (row) => {
                return <>{parseFloat(row.couponDiscount).toFixed(0)}%</>
            },
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Membership Plan
                </div>
            ),
            selector: (row) => {
                return row.membershipPlan ? (<>
                    {row.membershipPlan}
                </>) : (<>n/a</>)
            },
            width: "150px",
            wrap: true,
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Membership Discount
                </div>
            ),
            selector: (row) => {
                return row.membershipDiscountPercentage !== undefined ? (
                    <>{row.membershipDiscount.toFixed(2)} (<strong>{row.membershipDiscountPercentage}%</strong>)</>
                ) : (
                    <>n/a</>
                )

            },
            width: "120px",
        },

        // {
        //     name: (
        //         <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
        //             Total Discount
        //         </div>
        //     ),
        //     selector: (row) => {
        //         return <>{parseFloat(row.subtotal-row.bookingTotal).toFixed(2)}</>
        //     },
        // },

        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Total Amount
                </div>
            ),
            // selector: (row) => row.bookingTotal,
            selector: (row) => {
                return parseFloat(row.bookingTotal).toFixed(0)
            },
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Payment Method
                </div>
            ),
            selector: (row) => { return row.paymentMethod ? row.paymentMethod : "n/a" },
        },


        // {
        //     name: (
        //         <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
        //             Booking Time
        //         </div>
        //     ),
        //     selector: (row) => moment(row.booking_time, "HH:mm").format("HH:mm A"),
        //     wrap: true,
        // },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Time Passed
                </div>
            ),
            selector: (row) => {
                // return timeSince(row.updatedOn)
                // const currentDate = moment()
                let strdt = moment(row.booking_date).format("YYYY-MM-DD") + ' ' + moment(row.booking_time, "HH:mm a").format("HH:mm")
                // strdt="2023-12-28 15:02:00"
                let ago = moment(strdt, "YYYYMMDD HH:mm").fromNow(true)
                // strdt=moment(strdt).format("MMM DD YYYY HH:mm:ss")
                // const future = moment(strdt);
                // console.log("future",future,currentDate,future.diff(currentDate));
                return <><span className="text-capitalize">{ago}</span></>
                // return <>{moment(strdt).format("MMM DD YYYY HH:mm:ss")}</>
                // return <Countdown date={Date.now(strdt) + 60*10*100*10} />
                // return <>{moment(future.diff(currentDate)).format("mm")}</>
            },
            wrap: true,
        },

        {
            // row.bookingStatus
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Booking Status
                </div>
            ),
            selector: (row) => {
                return (
                    <>

                        <div class="alert alert-info text-capitalize font-weight-bold mt-2" role="alert">{row.bookingStatus}</div>

                        {/* {
                            row.bookingStatus === "pending" && (<>
                            <div class="alert alert-info text-capitalize mt-2" role="alert">{row.bookingStatus}</div>
                            </>)
                        }
                        {
                            row.bookingStatus === "complete" && (<>
                            <div class="alert alert-success text-capitalize mt-2" role="alert">{row.bookingStatus}</div>
                            </>)
                        }
                        {
                            row.bookingStatus === "cancelled" && (<>
                            <div class="alert alert-danger text-capitalize mt-2" role="alert">{row.bookingStatus}</div>
                            </>)
                        } */}



                        {/* <select
                            onChange={(e) => updateStatus(e, row.ID)}
                        >
                            <option value="pending" selected={row.bookingStatus == "pending" ? true : false} >Pending</option>
                            <option value="complete" selected={row.bookingStatus == "complete" ? true : false} >Completed</option>
                            <option value="cancelled" selected={row.bookingStatus == "cancelled" ? true : false}>Cancel</option>
                        </select> */}
                        {
                            row.bookingStatus === "pending" && (<>
                                <button class="btn btn-sm btn-danger mb-2"
                                    onClick={(e) => updateStatus("cancelled", row.ID)}
                                >
                                    Cancel
                                </button>
                            </>)
                        }


                    </>
                )
            },
            width: "150px"
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Payment Status
                </div>
            ),
            selector: (row) => row.paymenttype,
        },        
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Vendor Status
                </div>
            ),
            selector: (row) => {
                return row.acceptanceArray?.length > 0 ? (<>
                    {
                        row.acceptanceArray.map((item, index) => {
                            return <>
                                <strong>Vendor: </strong>{item?.vendorDetail?.name}

                                <span class="badge badge-success mt-2" style={{ display: "block" }}>{row?.acceptanceArray[0]?.acceptStatus}</span>


                            </>
                        })
                    }
                </>) : (

                    <>
                        <div className="row">
                            <div className="col my-2">
                                {/* <span style={{fontSize:"10px"}}>NOT ACCEPTED {row?.BookingID}{console.log("DDD",row)}</span> */}
                                {
                                    row.bookingStatus === "pending" ? (<>
                                        <select
                                            onChange={(e) => {
                                                setAVendor(e.target.value)
                                            }}
                                        // disabled
                                        >
                                            <option value="">Choose...</option>
                                            {
                                                <><VendorListByCategory booking={row?.BookingID} /></>
                                            }


                                        </select>

                                        <button class="btn btn-sm btn-primary"
                                            onClick={(e) => assignVendor(row.BookingID, aVendor)}
                                        >
                                            Assign Vendor
                                        </button>

                                    </>) : (<>
                                        {/* Reaching soon/Reached/ Started/Completed */}
                                        {/* {row.acceptanceArray !== undefined && row.acceptanceArray[0].acceptStatus} */}
                                        {/* {row?.acceptanceArray[0]?.acceptStatus} */}
                                    </>)
                                }


                            </div>
                        </div>
                    </>

                )
            },
            wrap: true,
            width: "150px"
        },
        // {
        //     name: (
        //         <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
        //             Discount Percent
        //         </div>
        //     ),
        //     selector: (row) => row.discount_percent,
        // },
        // {
        //     name: (
        //         <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
        //             Updated On
        //         </div>
        //     ),
        //     selector: (row) => moment(row.updatedOn).format("DD-MM-YYYY"),
        //     wrap: true,
        // },




        // {
        //     name: (
        //         <div
        //             style={{ fontSize: "14px", color: '#495057', marginLeft: "15px", fontWeight: "bolder" }}
        //         >
        //             Action
        //         </div>
        //     ),
        //     selector: (row) => row.action,
        // },
    ];




    const UpdateStateService = () => {
        console.log("ID", id);
        let data = {
            name: name,
        };

        HomeService.Update_Service(id, data)
            .then((res) => {
                console.log("updated res", res);
                if (res && res.status) {
                    toast.success("Updated Successfully");
                    setHide(true);
                    console.log("RESPONSEDELETE", res);
                    fetchAllBooking();
                    resetState();
                } else {
                    toast.error("Blog Category Name Already Exist");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const [searchOrderStatus, setSearchOrderStatus] = useState("")
    // const orderStatusTexts = ["pending", "cancelled", "complete","accept"]
    const orderStatusTexts = [
        {
            val: "pending",
            text: "Pending"
        },
        {
            val: "cancelled",
            text: "Cancelled"
        },
        {
            val: "complete",
            text: "Completed"
        },
        {
            val: "accept",
            text: "Accepted"
        }
    ]

    const [searchPaymentStatus, setSearchPaymentStatus] = useState("")
    const paymentStatusTexts = [
        {
            val: "pending",
            text: "Pending"
        },
        {
            val: "cancelled",
            text: "Cancelled"
        },
        {
            val: "complete",
            text: "Completed"
        },
        {
            val: "accept",
            text: "Accepted"
        }
    ]

    ////CSV Download//////////////

    const [csvData, setCsvData] = useState([]);

    const fillCSVData = (data) => {
        console.log("DATACSV", data);
        let arrCSV = data.map((item, index) => {
            return {
                SLNO: index + 1,
                BookingID: item?.BookingID,
                BookingDate: moment(item?.booking_date).format("DD-MM-YYYY"),
                Customer: item?.name,
                Mobile: item?.mobileNumber,
                TypeOfAddress: item?.address,
                Address: item?.address,
                Landmark: item?.landmark,
                City: item?.city,
                ZIP: item?.zipcode,
                ServiceTotal: item?.subtotal,
                CouponCode: item.couponCode ? item?.couponCode : "n/a",
                CouponDiscount: `${item?.couponDiscount}%`,
                Membership: item?.membershipPlan,
                MembershipDiscount: `${item?.membershipDiscount?.toFixed(2)} (${item?.membershipDiscountPercentage}%)`,
                TotalAmount: item?.bookingTotal?.toFixed(2),
                PaymentMode: item?.paymentMethod,
                PaymentStatus: item?.paymenttype,
                BookingStatus: item?.bookingStatus,
                Vendor: item?.acceptanceArray.length > 0 ? item?.acceptanceArray[0].acceptedBy : "n/a",
            };
        });
        setCsvData(arrCSV);
    };

    ////CSV Download//////////////

    return (
        <>
            <div component="div" className="TabsAnimation appear-done enter-done">
                <div className="main-card mb-3 card">
                    <div className="card-body">

                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                color: "#868e96",
                                margin: "35px",
                            }}
                            className="card-title"
                        >
                            Domestic Booking(s)
                        </div>
                        <div class="form-inline my-2">


                            <select
                                class="form-control mr-sm-2"
                                style={{ webkitAppearance: "inner-spin-button" }}
                                onChange={(e) => {
                                    setSearchOrderStatus(e.target.value);
                                }}
                            >
                                <option value="">--Order Status--</option>
                                {orderStatusTexts?.map((ele, key) => {
                                    return (
                                        <option
                                            selected={ele.val == searchOrderStatus ? true : false}
                                            value={ele.val}
                                            key={key}
                                        >
                                            {ele.text}
                                        </option>
                                    );
                                })}
                            </select>

                            <select
                                class="form-control mr-sm-2"
                                style={{ webkitAppearance: "inner-spin-button" }}
                                onChange={(e) => {
                                    setSearchPaymentStatus(e.target.value);
                                }}
                            >
                                <option value="">--Payment Status--</option>
                                {paymentStatusTexts?.map((ele, key) => {
                                    return (
                                        <option
                                            selected={ele.val == searchPaymentStatus ? true : false}
                                            value={ele.val}
                                            key={key}
                                        >
                                            {ele.text}
                                        </option>
                                    );
                                })}
                            </select>


                            <Label>From:&nbsp;</Label>
                            <div class="form-group">
                                <input
                                    class="form-control mr-sm-2"
                                    type="date"
                                    placeholder="From Date"
                                    aria-label="Search"
                                    value={fromDate}
                                    onChange={(e) => {
                                        setFromDate(e.target.value);
                                    }}
                                />
                            </div>
                            <Label>To:&nbsp;</Label>
                            <input
                                class="form-control mr-sm-2"
                                type="date"
                                placeholder="From Date"
                                aria-label="Search"
                                value={toDate}
                                onChange={(e) => {
                                    setToDate(e.target.value);
                                }}
                            />




                            <button class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1" type="submit"
                                onClick={onFilter}
                            >Filter</button>
                            <button class="btn btn-sm btn-outline-primary my-2 my-sm-0" type="submit"
                                onClick={viewAllData}
                            >View All</button>
                        </div>

                        {
                            loader ? (
                                <Loader />
                            ) : (
                                <>
                                    <CSVLink
                                        className="btn btn-sm btn-info"
                                        filename={`domestic-bookings-${moment().format("YYYYMMDD")}.csv`}
                                        data={csvData}
                                    >
                                        Export to CSV
                                    </CSVLink>
                                    <DataTable
                                        columns={columns}
                                        data={filteredData}
                                        pagination
                                        paginationPerPage={5}
                                        paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                                    />
                                </>
                            )
                        }

                        {/* <DataTable columns={columns} data={allData} pagination /> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddBooking;
