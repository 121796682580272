import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import DataTable from "react-data-table-component";
import Select from 'react-select';
import Swal from "sweetalert2";
import NewHomService from "../../Service/NewHomService";
import HomeService from "../../Service/HomeService";
import { useActionData, useParams } from "react-router-dom";
import { Alert } from "bootstrap";
import moment from "moment";
import Loader from "../../Component/Loader";
import VendorListByCategory from "../Booking/VendorListByCategory";
import VendorListByOption from "../Booking/VendorListByOption";
import { CSVLink } from "react-csv";
import { formatDate } from "../../Utils/Helpers";
import { Label } from "reactstrap";

var vendorIdGlobal = null;
var startDateGlobal = null;
var endDateGlobal = null;
var startTimeGlobal = null;
var endTimeGlobal = null;

const AMCForms = () => {
    const { bstatus } = useParams()
    // const [name, setName] = useState("");
    // const [price, setprice] = useState("");

    const [allData, setAllData] = useState([]);

    const [allmodeldata, setallModeldata] = useState([])
    const [allsubcatdata, setallsubcatdata] = useState([])

    const [showSubcategory, setShowSubcategory] = useState(false)
    const [allVendor, setAllVendor] = useState([]);
    const [totalCategory, settotalCategory] = useState([]);

    const [serviceTypeID, setServiceTypeID] = useState("")
    const [serviceCategoryID, setServiceCategoryID] = useState("");
    // const [serviceSubCategoryID, setServiceSubCategoryID] = useState("")
    const [displayDetails, setDisplayDetails] = useState(false)
    // const [assignVendorId, setAssignVendorId] = useState("")
    // const [startDate, setStartDate] = useState("")
    const [formDetails, setFormDetails] = useState({})
    const [subcat_Id, setsubcat_Id] = useState("");

    const [selectedData, setSelectedData] = useState();
    const [assignedVendorList, setAssignedVendorList] = useState([])

    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState("")
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        fetchAllVendor()
        setServiceTypeID("64d602b2e6419455b67e1ed8")
        fetchAllCategory("64d602b2e6419455b67e1ed8")
        bstatus && (
            setSearchOrderStatus(bstatus)
        )

        // fetchAllData();
        // fetchAllAssignedVendor();
        // console.log("assignedVendorList",assignedVendorList);
    }, []);

    const assignVerdor = (id, categoryId,userId) => {
        // console.log("DTA",id, vendorIdGlobal, startDateGlobal, startTimeGlobal,endDateGlobal,endTimeGlobal);
        if (id) {
            let data = {
                applicationFormId: id,
                vendorId: vendorIdGlobal,
                // startDate: startDateGlobal,
                // endDate: endDateGlobal,
                // startTime: startTimeGlobal,
                // endTime: endTimeGlobal,
                categoryId: categoryId,
                serviceTypeId: "64d602b2e6419455b67e1ed8",
                userId:userId,
            };
            console.log("data", data);
            NewHomService.AssignAMCVendor(data)
                .then((res) => {
                    console.log("updated res", res);
                    if (res && res.status) {
                        console.log("data", data);
                        toast.success("Vendor Assigned Successfully");
                        // fetchAllData(categoryId);
                        setServiceCategoryID(categoryId)
                        fetchAllData(categoryId);
                        setDisplayDetails(false)
                        fetchAllAssignedVendor();
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            toast.error("Error Occured!")
        }
    }


    const fetchAllAssignedVendor = () => {
        setLoader(true)
        NewHomService.FetchAllAssignedVendors()
            .then((res) => {
                // console.log("AllState", res.data);
                if (res && res?.status) {
                    setLoader(false)
                    let arr = res?.data;
                    setAssignedVendorList(arr)
                    console.log("assignedVendorList", assignedVendorList);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const fetchAllVendor = () => {
        NewHomService.FetchVendors()
            .then((res) => {
                console.log("AllStatesss", res?.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data;
                    setAllVendor(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const fetchAllSubCategoryArea = (id) => {
        HomeService.getAllSubcategory(id)
            .then((res) => {
                // console.log("AllSffetate", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data;
                    if (Object.keys(res?.data).length > 0) {
                        setShowSubcategory(true)
                        setallsubcatdata(arr);
                    }
                } else {
                    setallsubcatdata([]);
                    setShowSubcategory(false)
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };



    const fetchAllCategory = (id) => {
        HomeService.CategoriesviaService_type(id)
            .then((res) => {
                // console.log("AllState", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data;
                    settotalCategory(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };




    const fetchAllModeltype = (id) => {
        // const data={serviceCategoryID:catid}

        HomeService.getModeltypeUsingserviceId(id)
            .then((res) => {
                console.log("AllSttttttate", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data;
                    setallModeldata(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const onView = (id) => {
        NewHomService.FetchAMCFormDetails(id)
            .then((res) => {
                if (res && res.status) {
                    toast.success("Fetched successfully");
                    console.log("KDKD205", res?.data);
                    setFormDetails(res?.data)
                    setDisplayDetails(true)
                    window.scroll(0, 0)
                    fetchAllData(res?.data?.categoryData?._id);
                } else {
                    toast.error("Failed to retrive form data");
                    setDisplayDetails(false)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const fetchAllData = (serviceCategoryID) => {
        setLoader(true)
        NewHomService.FetchAMCFormsByCategory(serviceCategoryID)
            .then((res) => {
                console.log("DATAALL225", res?.data);
                if (res && res?.status) {
                    setLoader(false)
                    let arr = res?.data?.sort().reverse().map((item, index) => {
                        let vendorStatus = checkAssignedVendor(item._id, "acceptStatus")
                        let aVendorId = checkAssignedVendor(item._id, "vendorId")
                        // let vendorStatusText=checkAssignedVendor(row.ID, "acceptStatus")
                        console.log("ITEM214", aVendorId);
                        // console.log("X",x);
                        // console.log("llll", item?.materialTypeName);
                        // let arx = assignedVendor.filter((task) => {task.applicationFormId === "14"})
                        return {
                            Sl: index + 1,
                            ID: item?._id,
                            longitude: item?.location?.coordinates[0],
                            latitude: item?.location?.coordinates[1],
                            Name: item?.name,
                            Mobile: item?.mobile_number,
                            acceptStatus: item?.acceptStatus,
                            CategoryID: item?.categoryData?._id,
                            AreaID: item?.areaData?._id,
                            BookingStatus: item?.bookingStatus,
                            PaymentStatus: item?.paymentStatus,
                            BookingDate: item?.createdOn,

                            StartDate: item?.select_date,
                            StartTime: item?.select_time,
                            ExpiryDate: item?.expiry_date,

                            Category: item?.categoryData?.name,
                            State: item?.stateData?.name,
                            City: item?.cityData?.name,
                            Area: item?.areaData?.name,
                            PaymentStatus: item?.paymentStatus,
                            Cost: item?.AMCCost,

                            zipcode: item?.zipcode,
                            districtId: item?.districtId,
                            
                            address: item?.address ? item?.address : "N/A",
                            landmark: item?.landmark ? item?.landmark : "N/A",


                            action: (
                                <div
                                    // style={{ display: "flex", flexDirection: "coloum" }}
                                    style={{ display: "block", flexDirection: "coloum" }}
                                    className="my-2"
                                >
                                    {

                                        aVendorId ? (
                                            <>

                                            </>
                                        ) : (
                                            <>
                                                {

                                                    <>
                                                        <button
                                                            className="btn btn-sm btn-info mb-1"
                                                            onClick={(e) => assignVerdor(item?._id, item?.categoryData._id,item?.user_id)}
                                                        >Assign Vendor</button>
                                                    </>

                                                }


                                                {/* <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20"
                                                    onClick={(e) => assignVerdor(item?._id, item?.categoryData._id)}
                                                    className="mr-3"
                                                    style={{
                                                        height: "20px",
                                                        width: "20px",
                                                        cursor: "pointer",
                                                        marginRight: "20px",
                                                    }}
                                                >

                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.994 19a8.999 8.999 0 1 1 3.53-17.281M5.995 9l4 4 7-8m-1 8v5m-2.5-2.5h5" />
                                                </svg> */}
                                            </>
                                        )
                                    }

                                    <button
                                        className="btn btn-sm btn-success"
                                        onClick={() => onView(item?._id)}
                                    >View Details</button>


                                    {/* <svg
                                        onClick={() => onView(item?._id)}
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            cursor: "pointer",
                                            marginRight: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-pencil-square"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2Zm-3 15H4.828a1 1 0 0 1 0-2h6.238a1 1 0 0 1 0 2Zm0-4H4.828a1 1 0 0 1 0-2h6.238a1 1 0 1 1 0 2Z" />
                                        <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />

                                    </svg> */}

                                    {/* <button
                                        className="btn btn-sm btn-primary"
                                        onClick={(e) => assignVerdor(item?._id)}
                                    >
                                        Assign Vendor
                                    </button>

                                    <button
                                        className="btn btn-sm btn-info"
                                        onClick={() => onView(item?._id)}
                                    >
                                        View Task
                                    </button> */}




                                </div>
                            ),
                        };
                    });
                    console.log("LINE347", arr);
                    searchOrderStatus && (
                        arr = arr.filter((item) => {
                            if (item?.BookingStatus?.toLowerCase()?.includes(searchOrderStatus)) {
                                return item;
                            }
                        })

                    )
                    searchOrderStatus && (
                        setSearchOrderStatus(searchOrderStatus)
                    )
                    setFilteredData(arr)
                    setAllData(arr);
                    fillCSVData(arr);
                }
                // console.log("RESPONSE", res);
            })
            .catch((err) => {
                console.log("err", err);
                setLoader(false)
            });
    };

    const checkAssignedVendor = (applicationId, target) => {
        const retVal = assignedVendorList?.filter((item) => {
            return item.applicationFormId == applicationId
        })
        console.log("CONSOLE", applicationId, target);
        if (Object.keys(retVal).length > 0) {
            return retVal[0][target]
        } else {
            return ""
        }
        // console.log("vx",vx,"appid",applicationId);

        // return "64e9dca60d17df67e379b361"
    }

    const columns = [
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    SL
                </div>
            ),
            selector: (row) => row.Sl,
            sortable: true,
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Booking Date & Time
                </div>
            ),
            selector: (row) => {
                return (<>{formatDate(row.BookingDate)}</>)
            },
            wrap: true,
            width: "120px"
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Category
                </div>
            ),
            selector: (row) => {
                return row?.Category !== "" ? row?.Category : "n/a"
            },
            width: "150px"
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    State
                </div>
            ),
            selector: (row) => {
                return row?.State !== "" ? row?.State : "n/a"
            },
            width: "150px"
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    City
                </div>
            ),
            selector: (row) => {
                return row?.City !== "" ? row?.City : "n/a"
            },
            width: "150px"
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Area
                </div>
            ),
            selector: (row) => {
                return row?.Area !== "" ? row?.Area : "n/a"
            },
            width: "150px"
        },

        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Address
                </div>
            ),
            selector: (row) => row.address,
        },
        {
            name: (
                <div style={{ fontSize: "14px", color: '#495057', fontWeight: "bolder" }}>
                    Landmark
                </div>
            ),
            selector: (row) => row.landmark,
        },

        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Customer Name
                </div>
            ),
            selector: (row) => {
                return row?.Name !== "" ? row?.Name : ""
            },
            sortable: true,
            width: "200px",
            wrap: true
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Mobile
                </div>
            ),
            selector: (row) => {
                return row?.Mobile !== "" ? row?.Mobile : ""
            },
            width: "150px"
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Estimated Cost
                </div>
            ),
            selector: (row) => {
                return row?.Cost !== "" ? row?.Cost.toFixed(0) : "0"
            },
            width: "150px"
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Vendor Name
                </div>
            ),
            selector: (row) => {
                const aVendorId = checkAssignedVendor(row.ID, "vendorId")
                let vendorAcceptStatus = checkAssignedVendor(row.ID, "acceptStatus")
                return (
                    <>
                    
                        {
                            aVendorId !== "" 
                            // && vendorAcceptStatus !== "pending"
                              ? (
                                <>
                                    <select
                                        onChange={(e) => {
                                            vendorIdGlobal = e.target.value;
                                        }}
                                        // disabled
                                    >
                                        <option value="">Choose...</option>

                                        {
                                            allVendor?.map((ele, id) => {
                                                return (
                                                    <option
                                                        selected={ele._id == aVendorId ? true : false}
                                                        value={ele._id}
                                                        key={ele._id}
                                                    >
                                                        {ele.name}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </>
                            ) : (
                                <>

                                    <select
                                        onChange={(e) => {
                                            vendorIdGlobal = e.target.value;
                                        }}
                                    >
                                        <option value="">Choose...</option>
                                        {/* <VendorListByOption id={{ categoryId: row.CategoryID, longitude: row.longitude, latitude: row.latitude }} /> */}
                                        <VendorListByOption id={{ categoryId: row.CategoryID, userzipcode: row.zipcode, userDistrictId: row.districtId }} />
                                        {/* {
                                            allVendor?.map((ele, id) => {
                                                return (
                                                    <option
                                                        selected={ele._id == aVendorId ? true : false}
                                                        value={ele._id}
                                                        key={ele._id}
                                                    >
                                                        {ele.name}
                                                    </option>
                                                );
                                            })} */}
                                    </select>
                                </>

                            )
                        }

                    </>
                )
            },
            width: "200px",
            wrap: true
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Vendor Status
                </div>
            ),
            selector: (row) => {
                let vendorStatusText = checkAssignedVendor(row.ID, "acceptStatus")
                return vendorStatusText !== "" ? vendorStatusText : "n/a"
            },
            wrap: true,
            width: "150px"
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Start Date
                </div>
            ),
            selector: (row) => {
                const aStartDate = checkAssignedVendor(row.ID, "startDate")
                return (
                    <>
                        {moment(row.StartDate).format("DD-MM-YYYY")}
                        {/* {
                            aStartDate !== "" ? (
                                <>
                                    {moment(aStartDate).format("YYYY-MM-DD")}
                                </>
                            ) : (
                                <>
                                    <input
                                        type="date"
                                        min={moment(row.StartDate).format("YYYY-MM-DD")}
                                        className="form-control"
                                        value={moment(row.StartDate).format("YYYY-MM-DD")}
                                        onChange={(e) => {
                                            startDateGlobal = e.target.value;
                                        }}
                                    />
                                </>
                            )
                        } */}
                    </>
                )
            },
            width: "150px"
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Start Time
                </div>
            ),
            selector: (row) => {
                const aStartTime = checkAssignedVendor(row.ID, "startTime")
                return (
                    <>
                        {moment(row.StartTime, 'h:mm A').format('hh:mm A')}
                        {/* {
                            aStartTime !== "" ? (
                                <>
                                    {moment(aStartTime, 'HH:mm:ss').format('h:mm A')}
                                </>
                            ) : (
                                <>
                                    <input type="time"
                                        className="form-control"
                                        value={moment(row.StartTime, 'h:mm A').format('h:mm A')}
                                        onChange={(e) => {
                                            startTimeGlobal = e.target.value;
                                        }}
                                    />
                                </>
                            )
                        } */}

                    </>
                )
            },
            width: "150px"
        },

        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    End Date
                </div>
            ),
            selector: (row) => {
                const aEndDate = checkAssignedVendor(row.ID, "endDate")
                return (
                    <>
                        {moment(row.ExpiryDate).format("DD-MM-YYYY")}
                        {/* {
                            aEndDate !== "" ? (
                                <>
                                    {moment(aEndDate).format("YYYY-MM-DD")}
                                </>
                            ) : (
                                <>
                                    <input
                                        type="date"
                                        min={moment().format("YYYY-MM-DD")}
                                        className="form-control"
                                        value={moment(row.ExpiryDate).format("YYYY-MM-DD")}
                                        onChange={(e) => {
                                            endDateGlobal = e.target.value;
                                            // setStartDate(e.target.value)
                                        }}
                                    />
                                </>
                            )
                        } */}

                    </>
                )
            },
            width: "150px"
        },
        // {
        //     name: (
        //         <div
        //             style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        //         >
        //             End Time
        //         </div>
        //     ),
        //     selector: (row) => {
        //         const aEndTime = checkAssignedVendor(row.ID, "endTime")
        //         return (
        //             <>
        //                 {
        //                     aEndTime !== "" ? (
        //                         <>
        //                             {moment(aEndTime, 'HH:mm:ss').format('h:mm A')}
        //                         </>
        //                     ) : (
        //                         <>
        //                             <input type="time"
        //                                 className="form-control"
        //                                 // value={}
        //                                 onChange={(e) => {
        //                                     endTimeGlobal = e.target.value;
        //                                 }}
        //                             />
        //                         </>
        //                     )
        //                 }

        //             </>
        //         )
        //     },
        // },

        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Booking Status
                </div>
            ),
            selector: (row) => row.BookingStatus,
            wrap: true,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Payment Status
                </div>
            ),
            selector: (row) => row.PaymentStatus,
            wrap: true,
        },

        {
            name: (
                <div
                    style={{
                        fontSize: "14px",
                        color: "#495057",
                        marginLeft: "15px",
                        fontWeight: "bolder",
                    }}
                >
                    Action
                </div>
            ),
            selector: (row) => row.action,
            wrap: true,

        },
    ];

    const [searchOrderStatus, setSearchOrderStatus] = useState("")
    // const orderStatusTexts = ["pending", "cancelled", "complete"]
    const orderStatusTexts = [
        {
            val: "pending",
            text: "Pending"
        },
        {
            val: "cancelled",
            text: "Cancelled"
        },
        {
            val: "complete",
            text: "Completed"
        },
        {
            val: "accept",
            text: "Accepted"
        }
    ]

    const [searchPaymentStatus, setSearchPaymentStatus] = useState("")
    const paymentStatusTexts = [
        {
            val: "pending",
            text: "Pending"
        },
        {
            val: "cancelled",
            text: "Cancelled"
        },
        {
            val: "paid",
            text: "Paid"
        },
    ]

    const onFilter = () => {
        let searchData = allData;

        console.log("SSFFS790", searchData);
        //Order Date
        if (fromDate) {
            searchData = searchData.filter((item) => {
                let itemDate = new Date(item?.BookingDate)
                let frmDt = new Date(fromDate)
                let toDt = new Date(toDate)
                if (
                    // itemDate.getDate() >= frmDt.getDate() &&
                    // itemDate.getDate() <= toDt.getDate()

                    moment(itemDate).format("YYYY-MM-DD") >= moment(frmDt).format("YYYY-MM-DD") &&
                    moment(itemDate).format("YYYY-MM-DD") <= moment(toDt).format("YYYY-MM-DD")
                ) {

                    return item;
                }
            });
            setFilteredData(searchData);
            fillCSVData(searchData)
        }
        //Order Status
        if (searchOrderStatus) {
            searchData = searchData?.filter((item) => {
                if (item?.BookingStatus?.toLowerCase().includes(searchOrderStatus)) {
                    return item;
                }
            });
            setFilteredData(searchData);
            fillCSVData(searchData)
        }
        //Payment Status
        if (searchPaymentStatus) {
            searchData = searchData.filter((item) => {
                if (item?.PaymentStatus?.toLowerCase()?.includes(searchPaymentStatus)) {
                    return item;
                }
            });
            setFilteredData(searchData);
            fillCSVData(searchData)
        }
    }

    const viewAllData = () => {
        if (serviceCategoryID) {
            setSearchText("")
            setSearchOrderStatus("")
            setFilteredData(allData)
        } else {
            toast.error("Select Service Category")
        }

    }


    ////CSV Download//////////////

    const [csvData, setCsvData] = useState([]);

    const fillCSVData = (data) => {
        console.log("DATACSV", data);
        let arrCSV = data.map((item, index) => {
            return {
                SLNO: index + 1,
                BookingID: item?.ID,
                BookingDate: moment(item?.booking_date).format("DD-MM-YYYY"),
                Category: item?.Category,
                Customer: item?.Name,
                Mobile: item?.Mobile,
                Area: item?.Area,
                City: item?.City,
                State: item?.State,
                EstimatedCost: item?.Cost,
                StartDate: moment(item?.StartDate).format("DD-MM-YYYY"),
                StartTime: item?.StartTime,
                EndDate: moment(item?.EndDate).format("DD-MM-YYYY"),
                BookingStatus: item?.BookingStatus,
                PaymentStatus: item?.PaymentStatus,
            };
        });
        setCsvData(arrCSV);
    };

    ////CSV Download//////////////

    return (
        <>
            <div component="div" className="TabsAnimation appear-done enter-done">
                <div className="main-card mb-3 card">
                    <div className="card-body">
                        <div style={{ display: displayDetails ? "block" : "none" }}>
                            <h2>AMC Service Details</h2>
                            {/* {formDetails._id} */}
                            <table class="table table-bordered" >
                                <tbody>
                                    {
                                        formDetails.name !== undefined && formDetails.name !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Customer Name:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.name}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails.mobile_number !== undefined && formDetails.name !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Mobile:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.mobile_number}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.areaData?.name !== undefined && formDetails?.areaData?.name !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Location:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.areaData?.name}, {formDetails?.cityData?.name},{formDetails?.stateData?.name}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.categoryData?.name !== undefined && formDetails?.categoryData?.name !== null && formDetails?.categoryData?.name !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Requested Service:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>
                                                        {formDetails?.categoryData?.name}
                                                        {

                                                            formDetails?.subcategorydata?.name


                                                        }
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.numberOfService !== undefined && formDetails?.numberOfService !== "" && formDetails?.numberOfService > 0 &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Number Of {formDetails?.categoryData?.name}:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.numberOfService}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.serviceBrandData?.name !== undefined && formDetails?.serviceBrandData?.name !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Name Of Brand {
                                                        // formDetails?.categoryData?.name.split(" ")[0]
                                                    }:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.serviceBrandData?.name}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.selectTechlonogyData?.name !== undefined && formDetails?.selectTechlonogyData?.name !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>{formDetails?.categoryData?.name} Techlonogy {
                                                        // formDetails?.categoryData?.name.split(" ")[0]
                                                    }:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.selectTechlonogyData?.name}
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.materialtype !== undefined && formDetails?.materialtype !== null &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Contract work:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{
                                                        formDetails?.materialtype === true ? (
                                                            <>With material</>
                                                        ) : (
                                                            <>Without material</>

                                                        )


                                                    }</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.servicePackageData?.pack !== undefined && formDetails?.servicePackageData?.pack !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Service package:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{HomeService.packName(formDetails?.servicePackageData?.pack)}</td>
                                                </tr>
                                            </>
                                        )
                                    }

                                    {
                                        formDetails?.houseTypeData?.name !== undefined && formDetails?.houseTypeData?.name !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Type of House/Building:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.houseTypeData?.name}</td>
                                                </tr>
                                            </>
                                        )
                                    }

                                    {
                                        formDetails?.selectModelData?.type !== undefined && formDetails?.selectModelData?.type !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Model:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.selectModelData?.type} BHK</td>
                                                </tr>
                                            </>
                                        )
                                    }





                                    {
                                        formDetails?.anyOtherInformation !== undefined && formDetails?.anyOtherInformation !== "" && formDetails?.anyOtherInformation !== null &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Other Information:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.anyOtherInformation}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.MaterialPrice !== undefined && formDetails?.MaterialPrice !== "" && formDetails?.MaterialPrice > 0 &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Material Price:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{parseFloat(formDetails?.MaterialPrice).toFixed(2)}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.AccessoriesPrice !== undefined && formDetails?.AccessoriesPrice !== "" && formDetails?.AccessoriesPrice > 0 &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Accessories Price:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{parseFloat(formDetails?.AccessoriesPrice).toFixed(2)}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.WorkshipmanPrice !== undefined && formDetails?.WorkshipmanPrice !== "" && formDetails?.WorkshipmanPrice > 0 &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Workshipman Price:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{parseFloat(formDetails?.WorkshipmanPrice).toFixed(2)}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.AMCCost !== undefined && formDetails?.AMCCost !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left", fontSize: "125%" }}>Estimated Cost:</th>
                                                    <td className="col-9" style={{ textAlign: "left", fontSize: "125%" }}>{parseFloat(formDetails?.AMCCost).toFixed(2)}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.paymentStatus !== undefined && formDetails?.paymentStatus !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Payment Status:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.paymentStatus.toUpperCase()}</td>
                                                </tr>
                                            </>
                                        )
                                    }






                                </tbody>
                            </table>
                        </div>

                        {/* <div class="form-group">
                        <label for="exampleInputEmail1">
                            ServiceType<span style={{ color: "red" }}>*</span> :
                        </label>
                        <select
                            onChange={(e) => {
                                setServiceTypeID(e.target.value)
                                fetchAllCategory(e.target.value)
                                fetchAllModeltype(e.target.value)
                            }}
                        >
                            <option value="">Select</option>
                            {allVendor?.map((ele, id) => {
                                console.log(ele);
                                return (
                                    <option
                                        selected={ele._id == serviceTypeID ? true : false}
                                        value={ele._id}
                                        key={ele._id}
                                    >
                                        {ele.serviceName}
                                    </option>
                                );
                            })}
                        </select>
                    </div> */}
                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Service Category :
                            </label>
                            <select onChange={(e) => {
                                setServiceCategoryID(e.target.value)
                                e.target.value ? fetchAllData(e.target.value) : setAllData([]);
                                setDisplayDetails(false)
                                fetchAllAssignedVendor();
                            }}>
                                <option value="">--- All Category ---</option>
                                {totalCategory?.map((ele, id) => {
                                    console.log(ele);
                                    return (
                                        <option
                                            selected={ele._id == serviceCategoryID ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div
                            class="form-group"
                            style={{ display: allsubcatdata.length > 0 ? "block" : "none" }}
                        >
                            <label for="exampleInputEmail1">Sub Category :</label>
                            <select
                                onChange={(e) => {
                                    setsubcat_Id(e.target.value);
                                    fetchAllData(e.target.value);
                                }}
                            >
                                <option value="">Select</option>
                                {allsubcatdata?.map((ele, id) => {
                                    // console.log(ele);
                                    return (
                                        <option
                                            selected={ele._id == subcat_Id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>



                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                color: "#868e96",
                                margin: "35px",
                            }}
                            className="card-title"
                        >
                            AMC Booking(s)
                        </div>

                        <div class="form-inline my-2">
                            {/* <input class="form-control mr-sm-2" type="date" placeholder="Filter by category name" aria-label="Search"
                                value={searchText}
                                onChange={(e) => {
                                    setSearchText(e.target.value)
                                }}
                            /> */}

                            <select
                                class="form-control mr-sm-2"
                                style={{webkitAppearance: "inner-spin-button"}}
                                onChange={(e) => {
                                    setSearchOrderStatus(e.target.value);
                                }}
                            >
                                <option value="">--Order Status--</option>
                                {orderStatusTexts?.map((ele, key) => {
                                    return (
                                        <option
                                            selected={ele.val == searchOrderStatus ? true : false}
                                            value={ele.val}
                                            key={key}
                                        >
                                            {ele.text}
                                        </option>
                                    );
                                })}
                            </select>

                            <select
                                class="form-control mr-sm-2"
                                style={{webkitAppearance: "inner-spin-button"}}
                                onChange={(e) => {
                                    setSearchPaymentStatus(e.target.value);
                                }}
                            >
                                <option value="">--Payment Status--</option>
                                {paymentStatusTexts?.map((ele, key) => {
                                    return (
                                        <option
                                            selected={ele.val == searchPaymentStatus ? true : false}
                                            value={ele.val}
                                            key={key}
                                        >
                                            {ele.text}
                                        </option>
                                    );
                                })}
                            </select>

                            <Label>From:&nbsp;</Label>
                            <div class="form-group">
                                <input
                                    class="form-control mr-sm-2"
                                    type="date"
                                    placeholder="From Date"
                                    aria-label="Search"
                                    value={fromDate}
                                    onChange={(e) => {
                                        setFromDate(e.target.value);
                                    }}
                                />
                            </div>
                            <Label>To:&nbsp;</Label>
                            <input
                                class="form-control mr-sm-2"
                                type="date"
                                placeholder="From Date"
                                aria-label="Search"
                                value={toDate}
                                onChange={(e) => {
                                    setToDate(e.target.value);
                                }}
                            />

                            <button class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1" type="submit"
                                onClick={onFilter}
                            >Filter</button>
                            <button class="btn btn-sm btn-outline-primary my-2 my-sm-0" type="submit"
                                onClick={viewAllData}
                            >View All</button>
                        </div>

                        {
                            loader ? (
                                <>
                                    <Loader />
                                </>
                            ) : (
                                <>
                                    {
                                        filteredData.length > 0 && (
                                            <>
                                                <CSVLink
                                                    className="btn btn-sm btn-info"
                                                    filename={`amc-bookings-${moment().format("YYYYMMDD")}.csv`}
                                                    data={csvData}
                                                >
                                                    Export to CSV
                                                </CSVLink>
                                            </>
                                        )
                                    }
                                    {
                                        loader ? (<Loader />) : (
                                            <DataTable
                                                columns={columns}
                                                data={filteredData}
                                                pagination
                                                noDataComponent="No forms are submitted yet"
                                            />
                                        )
                                    }


                                </>
                            )
                        }


                    </div>
                </div>
            </div>
        </>
    );
};

export default AMCForms;
